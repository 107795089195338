/* Overall body */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

/* Modal container */
.modal-container {
  background: #1f1f1f;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
}

/* Close button */
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close-btn:hover {
  color: #ff6347;
}

/* Modal title */
.modal-title {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

/* Modal content */
.modal-content {
  color: #ddd;
  line-height: 1.6;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

/* Form input fields */
.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #ff6347;
  outline: none;
}

/* Submit button */
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #ff6347;
  color: white;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #e5533e;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-container {
    width: 95%;
    padding: 15px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .modal-content {
    font-size: 1rem;
  }

  .input-field {
    font-size: 0.9rem;
  }

  .submit-btn {
    font-size: 1rem;
  }
}

/* For very small devices */
@media (max-width: 480px) {
  .modal-container {
    width: 95%;
    padding: 10px;
  }

  .modal-title {
    font-size: 1.2rem;
  }

  .modal-content {
    font-size: 0.95rem;
  }

  .submit-btn {
    font-size: 0.9rem;
  }
}
