* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #2e294e;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

nav p {
  color: white;
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
}

.navbar {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  transition: transform 0.3s ease;
}

.navbar li {
  color: white;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
  font-family: "Roboto", sans-serif;
  transition: color 0.3s ease;
}

.navbar li:hover {
  color: #ddd;
}

.navbar li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.navbar li:hover::after {
  width: 100%;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .navbar {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #2e294e;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    transform: translateY(-200%);
    transform-origin: top;
  }

  .navbar.open {
    transform: translateY(0%);
  }

  .navbar li {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  nav p {
    font-size: 20px;
  }

  .navbar li {
    font-size: 14px;
  }
}

/* Content Container */
.content-container {
  margin-top: 80px; /* Space for fixed navbar */
  padding: 20px;
  font-family: "Arial", sans-serif;
  background: #f4f4f9;
  border-radius: 8px;
  max-width: 1200px;
  margin: 100px auto; /* Center align */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* Premium Look for Buttons (Example) */
button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}
.warni {
  font: 550 1rem / 1.625rem "Open Sans", sans-serif;
}

/* Container Styling */
.table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  animation: fadeIn 1s ease-in-out;
}

/* Individual Table Box */
.table-box {
  background: linear-gradient(
    145deg,
    #ffffff,
    #f8f8f8
  ); /* Soft white gradient */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 392px;
  padding: 1.5rem;
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.table-box:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.25);
}

/* Header of Each Plan */
.table-box h3 {
  text-align: center;
  font-size: 1.8rem;
  color: #1b1e7b; /* Vibrant blue for headers */
  margin-bottom: 1.5rem;
  font-family: "Poppins", sans-serif;
}

/* Table Content */
.table-box table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
}

.table-box table td {
  font-size: 1rem;
  color: #4d4d4d; /* Neutral grey for text */
  padding: 0.8rem 1rem;
  text-align: justify; /* Justify text alignment */
  line-height: 1.6; /* Improve readability */
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s;
}

.table-box table td:hover {
  background-color: #eaf6ff; /* Light blue highlight on hover */
}

/* Highlights for Text Labels */
.table-box table td strong {
  color: #e74c3c; /* Vibrant red for key points */
  font-weight: 600;
}

/* Adjust Spacing for Table Rows */
.table-box table tr {
  margin-bottom: 1rem;
}

/* Note Styling */
.warni {
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #7f8c8d; /* Subtle grey */
  text-align: center;
  font-style: italic;
  animation: fadeInUp 1.5s ease-in-out;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .table-box {
    width: 900%;
  }
}
