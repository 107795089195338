* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content-container {
  padding: 40px;
  font-family: "Arial", sans-serif;
  background: linear-gradient(to bottom right, #f9f9f9, #e6e6e6);
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 50px auto;
}

h1 {
  font-size: 2.5rem;
  color: #1b1e7b;
  margin-bottom: 20px;
}

h1.first {
  text-align: center;
  font-weight: bold;
  color: #1b1e7b;
}

h1.second {
  margin-top: 40px;
  color: #1b1e7b;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  border-left: 4px solid #dc2430;
  padding-left: 15px;
}

hr {
  width: 60%;
  margin: 20px auto;
  border: none;
  height: 2px;
  background: #dc2430;
  border-radius: 2px;
}

.content-para,
.secondp {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
  margin: 15px 0;
  text-align: justify;
}

.secondp {
  font-weight: 500;
  color: #444;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 40px;
  padding: 0 20px;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.grid:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

.grid img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.grid h3 {
  font-size: clamp(1rem, 2vw, 1.2rem);
  text-align: center;
  margin-bottom: 10px;
}

.grid p {
  font-size: clamp(0.8rem, 1.5vw, 0.9rem);
  text-align: center;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .content-container {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  .content-para,
  .secondp {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .content-container {
    padding: 15px;
  }

  h1 {
    font-size: 1.8rem;
  }

  .content-para,
  .secondp {
    font-size: 0.9rem;
  }

  .grid {
    padding: 15px;
  }

  .grid h3 {
    font-size: 1rem;
  }

  .grid p {
    font-size: 0.8rem;
  }
}

.new-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  flex-wrap: wrap;
}

.text-container {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.text-container h1 {
  font-size: 2.5rem;
  color: #1b1e7b;
  margin-bottom: 20px;
}

.text-container p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.text-container .bold-text {
  font-weight: bold;
  font-size: 1.2rem;
  color: #1b1e7b;
}

.image-container {
  flex: 0 0 500px;
  height: auto;
  max-width: 100%;
  margin-left: 20px;
  min-width: 300px;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .new-section {
    flex-direction: column;
    align-items: center;
  }

  .text-container {
    max-width: 100%;
    text-align: center;
  }

  .image-container {
    margin-left: 0;
    width: 80%;
    height: auto;
  }
}
/* New Section 2 Layout */
.new-section-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  padding: 20px;
  flex-wrap: wrap;
}

.text-container {
  flex: 1;
  max-width: 60%;
  padding: 20px;
}

.text-container h1 {
  font-size: 2.5rem;
  color: #1b1e7b;
  margin-bottom: 20px;
}

.content {
  margin-top: 20px;
}

.content-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.content-icon {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.content-text h2 {
  font-size: 1.5rem;
  color: #1b1e7b;
  margin-bottom: 10px;
}

.content-text p {
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

.image-container {
  flex: 1;
  max-width: 40%;
  padding: 20px;
}

.section-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .new-section-2 {
    flex-direction: column;
    align-items: center;
  }

  .text-container,
  .image-container {
    max-width: 100%;
    padding: 10px;
  }

  .content-item {
    flex-direction: column;
    align-items: center;
  }

  .content-icon {
    margin-bottom: 10px;
  }
}

.new-section-3 {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 50px;
}

.box-container {
  background-color: #f2f3fd;
  border-radius: 15px;
  padding: 40px;
  max-width: 900px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.new-section-3 h1 {
  font-size: 2rem;
  color: #1b1e7b;
  margin-bottom: 20px;
  font-weight: 600;
}

.new-section-3 p {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .box-container {
    padding: 20px;
  }

  .new-section-3 h1 {
    font-size: 1.5rem;
  }

  .new-section-3 p {
    font-size: 1rem;
  }
}

.new-section-4 {
  padding: 60px 20px;
}

.heading-container {
  text-align: center;
  margin-bottom: 40px;
}

.heading-container h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1b1e7b;
}

.underline {
  border: none;
  height: 3px;
  width: 80px;
  background-color: #1b1e7b;
  margin-top: 10px;
  border-radius: 5px;
}

.heading-container p {
  color: #6b747b;

  font: 550 1rem / 1.625rem "Open Sans", sans-serif;
  line-height: 1.6;
  max-width: 800px;
  margin: 20px auto;
}

.table-container {
  display: flex;

  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.table-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.table-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.table-box h3 {
  font-size: 1.5rem;
  color: #1b1e7b;
  margin-bottom: 15px;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 12px 10px;
  font-size: 1rem;
  color: #333;
  text-align: center;
  line-height: 1.6;
  border-bottom: 1px solid #e0e0e0;
}

strong {
  font-weight: bold;
  color: #1b1e7b;
}

@media (max-width: 1024px) {
  .table-container {
    flex-direction: column;
    align-items: center;
  }

  .table-box {
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  .table-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .table-box {
    margin-bottom: 20px;
  }

  /*  the margin between the first two tables */
  .table-container .table-box:nth-child(1),
  .table-container .table-box:nth-child(2) {
    margin-right: 60px;
    margin-left: 60px;
  }

  .table-container .table-box:nth-child(3) {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  td {
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    padding: 12px 10px;
  }
}
.warnin {
  font-family: sans-serif;
}

.section-5 {
  padding: 60px 20px;
  background-color: #f2f3fd;
}

.register-box {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.register-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.register-heading {
  font-size: 2rem;
  font-weight: 600;
  color: #1b1e7b;
  margin-bottom: 15px;
}

.email-address {
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
  margin-top: 10px;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .register-box {
    width: 100%;
    padding: 30px;
  }

  .register-heading {
    font-size: 1.8rem;
  }

  .email-address {
    font-size: 1.2rem;
  }
}

.new-section-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;

  border-radius: 10px;
  max-width: 500px;
}

.contact-heading {
  font-size: 1.8rem;
  color: #1b1e7b;
  margin-bottom: 20px;
  text-align: center;
}

/* card styling area from down */

.contact-card {
  position: relative;
  width: 400px;
  text-align: center;
  height: 200px;
  perspective: 1000px;
}

.contact-card .card-front,
.contact-card .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.8s ease;
}

.contact-card .card-front {
  background-color: #ffffff;
}

.contact-card .card-back {
  background-color: #1b1e7b;
  color: #ffffff;
  transform: rotateY(180deg);
}

.contact-card.flipped .card-front {
  transform: rotateY(180deg);
}

.contact-card.flipped .card-back {
  transform: rotateY(0);
}

.flip-button {
  background-color: #1b1e7b;
  position: relative;
  top: 20px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.flip-button:hover {
  background-color: #f2f3fd;
  color: #1b1e7b;
  transform: scale(1.05);
}

.footer {
  background-color: #1a1a1a;
  color: #f1f1f1;
  font-family: Arial, sans-serif;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-container > div {
  flex: 1;
  min-width: 250px;
}

.privacy-policy h3,
.footer-links h3,
.contact-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.privacy-policy p {
  font-size: 0.9rem;
  line-height: 1.6;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.3s;
}

.footer-links li:hover {
  color: #00bcd4;
}

.contact-info p {
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.footer-bottom {
  margin-top: 30px;
  text-align: center;
  font-size: 0.85rem;
  border-top: 1px solid #333;
  padding-top: 15px;
}

.footer-bottom p {
  margin: 0;
  color: #888;
}

.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text and slideshow side by side */
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.text-content {
  flex: 1; /* Takes up the left portion for the text */
  font-family: Arial, sans-serif;
  color: #333;
}

.slideshow {
  flex: 1; /* Takes up the right portion for the slideshow */
  position: relative;
  width: 300px; /* Set a smaller size for images */
  height: 230px; /* Adjust height to match the width */
  overflow: hidden;
  border-radius: 8px;
}

.slideshow img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  animation: slide 15s infinite; /* Increase animation duration */
}

.slideshow img:nth-child(1) {
  animation-delay: 0s;
}
.slideshow img:nth-child(2) {
  animation-delay: 3s;
}
.slideshow img:nth-child(3) {
  animation-delay: 6s;
}
.slideshow img:nth-child(4) {
  animation-delay: 9s;
}
.slideshow img:nth-child(5) {
  animation-delay: 12s;
}

@keyframes slide {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.company-title {
  position: relative;
  left: 170px; /* Default for larger screens */
}

/* For tablets and smaller devices */
@media (max-width: 1024px) {
  .company-title {
    left: 100px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .company-title {
    left: 0px;
  }
}
