.faq-chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chatbot-button {
  background-color: #1b1e7b;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.chatbot-button.active {
  background-color: #f2f3fd;
  color: #1b1e7b;
}

.chatbot-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.chat-window {
  background: #ffffff;
  width: 320px;
  max-height: 500px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  animation: slideIn 0.4s ease-in-out;
}

.chat-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1b1e7b;
  margin-bottom: 20px;
  text-align: center;
}

.chat-questions {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-question-item {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  color: #1b1e7b;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
}

.chat-question-item:hover {
  background-color: #f2f3fd;
}

.chat-answer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.question {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1b1e7b;
  margin-bottom: 10px;
}

.answer {
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}

.go-back-button {
  background-color: #1b1e7b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-back-button:hover {
  background-color: #f2f3fd;
  color: #1b1e7b;
}

.chat-window {
  width: 90%;
}

@media (min-width: 768px) {
  .chat-window {
    width: 320px;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
